@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Outfit&family=Roboto&display=swap');

:root{
  --bg-color: #DEEEF520;
  --bg-color-2:#C7E7F4;
  --bg-color-footer: #DEEEF5;
  --bg-button-hero:#FFD54F;
  --bg-button-hero-hover:#e7c245;
  --bg-header-mobile:#0FAEF1;
  --bg-header-mobile-hover:#0c9edd;
  --bg-card-advantages:#C7E7F4;
  --bg-card-advantages-hover:#b4d5e2;
  --bg-footer-mobile: #B0E0F4;
  

  --font-color-white: #F4F4F4;
  --font-color-black: #000;
  --font-color-blue: #54C4F3;
  --font-color-blue-eases: #99D9F3;
  
  --font-color-title3: #54C4F3;
 
  --font-size-logo: 3rem;

  --font-famile-brand:  'Kanit', sans-serif;
  --font-root: 'Roboto', sans-serif;
  --font-title:'Outfit', sans-serif;

  

}


body {
  font-family:var(--font-root) ;
  background-color: var(--bg-color);
  overflow-x: hidden;
  width: 100vw;
}

.app .brand{
  font-family: var(--font-famile-brand);
}


h1,h2, h3,h4{
  font-family: var(--font-title);
} 
h1{
  font-size: 5rem;
}
h2{
  font-size: .87rem;
}
h3{
  font-size: 1.5rem;
}
