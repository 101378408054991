.advantages{
  font-family: var( --font-root);
}
.advantages .card{
    background-color: var(--bg-card-advantages);
    border: none;
    width: 170px;
    height: 180px;

    margin-top: -45px;
    margin-left: 20px;

    border-radius: 16px;
    
    transition: all .3s;
}
.advantages .card:hover{
  background-color: var( --bg-card-advantages-hover);
}
.advantages .card>h5{
   font-size: 20px;
}
.advantages img{
    width: 60px;
    height: 60px;
}
@media (max-width: 767.98px) { 


  
}