.header {  
    background-color: transparent; 
}

.header .brand{
    font-family: var(--font-famile-brand);
    text-decoration: none;
    font-size: var(--font-size-logo);
    color:#fff
}
.nav-link{
    color: #fff;
    margin-left: 70px;
    font-size: 1.25rem;
    font-family: var(--font-root);
}
.navbar-toggler-icon{
    background-image: url('../../assets/icons/menu-icon.svg');
}
.navbar-toggler{
    border: none;
}
.bgTrue{
    background-color: var(--bg-header-mobile);
}

@media (max-width: 767.98px) { 
    .header {  
        background-color: var(--bg-header-mobile); 
    }
}
