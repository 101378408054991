.download{
  background-image: url('../../../assets/imgs/bg-banner-download.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height:350px ;
  color: #fff;
  margin-top: 140px;
  
}

.download .img-primary{
  max-height: 543px ; 
  position: absolute;
  padding: 0;
  margin: 0;
  margin-top: -80px;
  margin-left: -10vw ;
}

.downloadTitle{
  margin-left: 50vw;
  max-width: 517px;
}
.downloadTitle h2{

  font-family: var(--font-title);
  font-size: 60px;
}
.downloadTitle p{

  font-family: var(--font-title);
  font-size: 20px;


}
.badgets{
  margin-bottom: 20px;
  max-height: 47px ;
  max-width:  150px ;
}
.badgets:nth-child(2){
  margin-left: 10px;
}


@media (max-width: 767.98px) { 
  
  .download{
    background-size: cover;

  }
  .download .img-primary{
    position: relative;
    margin-top: 0px;
    margin-left: 0px ;
  }
  .downloadTitle p{
    
    max-width: 80%;
    margin-left: 10%;
    margin-top: 34px ;
  
  }
 
  .downloadTitle{
    margin-left: 0px;
  
  } 
  .badgets:nth-child(2){
    margin-left: 0px;
  }

 }

@media (max-width: 991.98px) { 
  .download{
    background-size: cover;

  }

  .download .img-primary{
    margin-top: 0px;
    position: relative;
  }

 }
 @media (max-width: 1199.98px) { 
  
  .downloadTitle{
    margin-left: 0;
  
  }

  .download .img-primary{
    margin-top: -80px;
  }
  }