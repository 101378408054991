.advantages2{
    color: var(--font-color-blue-eases);
   margin-top: 100px;
}
.advantages2  h3{
   font-size: 48px;
   line-height: 10px
}
.advantages2  p{
   font-size: 34px;
   margin-bottom: 135px;
}

.advantages2 .cards img{
    margin-left: 68px;

}
.advantages2 .cards button{
  background: none;
  border: none;
}

@media (max-width: 767.98px) {  
   .advantages2  h3{
      font-size: 48px;
      line-height: 20px;
   }
   .advantages2  p{
      font-size: 24px;
   }
   .advantages2 .cards img{
      margin-left: 0px;
  }
}

